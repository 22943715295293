<template>
<div>
<div class="card p-2 bg-card">
     <div class="align-center-flex">
        <div>
          <input
            v-model="searchResult"
            placeholder="Search"
            class="mr-bottom form-control input-sm flex"
          />
        </div>
        <div>
          <button
            @click="addUser()"
            class="btn pointer btn-outline-primary btn-sm margin-right"
            :disabled="!this.$store.state.auth.permissions.includes('user-create')"
          >
            + Add
          </button>
        </div>
      </div>
</div>
  <b-card>
    <div class="user-select">
     

      <div class="card-company-table card card-congratulation-medal">
        <div class="mb-0 table-responsive border rounded">
          <table role="table" class="table b-table table-hover">
            <thead role="rowgroup header">
              <tr role="row">
                <th role="columnheader" scope="col">#</th>
                <th role="columnheader" scope="col">Firstname</th>
                <th role="columnheader" scope="col">Lastname</th>
                <th role="columnheader" scope="col">Email</th>
                <!-- <th role="columnheader" scope="col">Designation</th> -->

                <th role="columnheader" scope="col" class="text-align-right">
                  Action
                </th>
              </tr>
            </thead>
            <tbody class="rowgroup">
              <tr
                v-for="(user, index) in allUsers"
                :key="user._id"
                role="row"
                class="custom-table-row"
              >
              <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
                <td role="cell">{{ user.firstname }}</td>
                <td role="cell">{{ user.lastname }}</td>
                <td role="cell">{{ user.email }}</td>

                <td
                  role="cell"
                  class="align-center flex-display text-align-right"
                >
                  <span class="icon-box-new-user pointer">
                    <feather-icon
                      class="rotate"
                      icon="EyeIcon"
                      size="20"
                      @click="viewDetail(user._id)"
                    />
                  </span>
                  <span
                    @click="showModal(user.user_id)"
                    class="icon-box-new-user pointer"
                    v-if="user.user_id != uid"
                  >
                    <feather-icon
                      class="delete-color rotate"
                      icon="Trash2Icon"
                      size="20"
                    />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="card-body">
            <b-modal
              id="modal-danger"
              ref="modal-danger"
              ok-only
              ok-variant="danger"
              @ok="deleteUser()"
              modal-class="modal-danger"
              centered
              title="Delete !"
              :ok-disabled="!this.$store.state.auth.permissions.includes('user-create')"
            >
              <b-card-text> Are You Sure to Delete the User ? </b-card-text>
            </b-modal>
          </div>
        </div>
      </div>
      <div class="align-center-div">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          hide-goto-end-buttons
          :total-rows="lastPage * perPage"
          class="float-right"
        />
      </div>
    </div>
  </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import { BPagination, BModal, BCardText, BCard } from "bootstrap-vue";
import store from '@/store'
export default {
  data: () => ({
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
    rows: 1,
    allUsers: [],
    userId: "",
    searchResult: "",
    uid:'',
  }),
  components: {
    BModal,
    BPagination,
    BCardText,
    BCard,
  },

  props: {},
  watch: {
    currentPage(value) {
      this.getUser(value);
    },
    searchResult() {
      this.searchResultData(this.currentPage);
    },
  },
  mounted() {
    this.getUser(this.currentPage);
  },
  created() {
    this.uid = `${this.$store.state.auth.user._id}`
   
  },
  computed: {},
  methods: {
    searchResultData(num) {
      if ((num !== null) | (num !== 0)) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_ONECSF_API_URL +
            `/users?name=${this.searchResult}`,
          params: { page: num, limit: this.perPage },
        };

        this.$http(options)
          .then((res) => {
            this.allUsers = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.lastPage = res.data.data.last_page;
            this.perPage = res.data.data.per_page;
            this.rows = this.lastPage * 10;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    addUser() {
      this.$router.push({ name: "adduser" });
    },
    viewDetail(id) {
      this.$router.push({ name: "userdetail", params: { id: id } });
    },
    getUser(num) {
      if ((num !== null) | (num !== 0)) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_ONECSF_API_URL + `/users?name=${this.searchResult}`,
          params: { page: num ,limit: this.perPage},
        };
        this.$http(options)
          .then((res) => {
            this.allUsers = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.lastPage = res.data.data.last_page;
            this.perPage = res.data.data.per_page;
            this.rows = this.lastPage * 10;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteUser(userid) {
      userid = this.userId;
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/users/${userid}`,
      };
      this.$http(options)
        .then((res) => {
          this.getUser();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showModal(itemid) {
      this.userId = itemid;
      this.$refs["modal-danger"].show();
    },
  },
};
</script>

<style>
.header {
  font-size: 20px;
  font-weight: 600;
}

.align-center-flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.align-center-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.padding-20 {
  padding: 20px;
}
.transpent {
  background-color: rgba(130, 134, 139, 0.12) !important;
}
/* .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #f3f2f7 !important;
  color: #6e6b7b;
} */

.align-right {
  display: flex;
  justify-content: flex-end;
}
.user-select {
  user-select: none;
}
.icon-box-new-user {
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  display: inline-flex;
}

.mr-bottom {
  margin-bottom: 10px;
}

.pointer {
  cursor: pointer;
}
.delete-color {
  color: #ea5455 !important;
}
.validation {
  color: red;
  font-size: 12px;
  position: relative;
  top: -10px;
}

.table th,
.table td {
  border-top: 1px solid #e3e0e9;
}

.rowgroup tr:hover {
  background-color: #f6f6f9;
  cursor: pointer !important;
}

.text-align-right {
  text-align: right;
}

.modal .modal-header .close {
  position: relative;
  top: 4px;
  left: -10px;
}
th{
  background: #472183 !important;
  color: #fff;
}
</style>
